import {
  UNLIMITED_ACCESS_LIMIT,
  NO_ACCESS_LIMIT,
  INDEFINITE_TIMEFRAME
} from 'constants/feature_limits'
import {
  MEMBERSHIP_STATUSES,
  MEMBERSHIP_STATUSES_LOOKUP
} from 'constants/membership'
import { PERMISSION_KEYS } from '../account/permissions'

export * from './shared'
export * from './base_plans'
export * from './campaigns'
export * from './checkout_line_items'

export const URL_LINK_TYPE_MAP = {
  membership: 'mt'
}

export const PLAN_STATUSES = {
  pending: 'pending',
  active: 'active',
  expired: 'expired'
}

export const USER_SUBSCRIPTION_STATUS_FILTER_DROPDOWN = [
  {
    name:
      MEMBERSHIP_STATUSES_LOOKUP[MEMBERSHIP_STATUSES.CANCELLED_AT_PERIOD_END],
    value: MEMBERSHIP_STATUSES.CANCELLED_AT_PERIOD_END
  },
  {
    name: MEMBERSHIP_STATUSES_LOOKUP[MEMBERSHIP_STATUSES.ACTIVE],
    value: MEMBERSHIP_STATUSES.ACTIVE
  },
  {
    name: MEMBERSHIP_STATUSES_LOOKUP[MEMBERSHIP_STATUSES.DECLINED_PAYMENT],
    value: MEMBERSHIP_STATUSES.DECLINED_PAYMENT
  },
  {
    name:
      MEMBERSHIP_STATUSES_LOOKUP[
        MEMBERSHIP_STATUSES.MEMBERSHIP_EXPIRING_SHORTLY
      ],
    value: MEMBERSHIP_STATUSES.MEMBERSHIP_EXPIRING_SHORTLY
  },
  {
    name:
      MEMBERSHIP_STATUSES_LOOKUP[MEMBERSHIP_STATUSES.CALCULATING_LAUNCH_LEVEL],
    value: MEMBERSHIP_STATUSES.CALCULATING_LAUNCH_LEVEL
  },
  // Special status. In the back-end, it will check if Status of subscription is Active AND there is an "end_trial_date".
  {
    name: 'Trial',
    value: 'trial'
  }
]

export const PLAN_STATUS_FILTER_DROPDOWN = [
  { name: 'Active', value: PLAN_STATUSES.active },
  { name: 'Pending', value: PLAN_STATUSES.pending },
  { name: 'Expired', value: PLAN_STATUSES.expired }
]

export const PLAN_MEMBERSHIP_TYPE = 'MembershipType'

export const URL_PLAN_TYPE_MAP = {
  membership: PLAN_MEMBERSHIP_TYPE
}

export const PLAN_TYPE_URL_MAP = {
  [PLAN_MEMBERSHIP_TYPE]: 'membership'
}

export const DEFAULT_PLAN_STATUSES_FILTERED = [
  PLAN_STATUSES.active,
  PLAN_STATUSES.pending
]

export const PLAN_TABLE_SORTED_COLUMN_DEFAULT = 'created_at'
export const PLAN_TABLE_SORT_DIRECTION_DEFAULT = 'desc'

// Some users still have a legacy key for access to Niche Hunter
// We want to include that in the Admin Panel but not in the Team Feature Access
//
// Also including "All Seller Tools" here as a legacy field that controls
// access to all seller tooling. Should eventually replace that with individual feature access
export const GATED_FEATURE_DROPDOWN_OPTIONS = [
  {
    name: 'Competitive Intelligence',
    accessor: PERMISSION_KEYS.COMPETITIVE_INTELLIGENCE,
    disabled: false
  },
  { name: 'Extension', accessor: PERMISSION_KEYS.EXTENSION, disabled: false },
  {
    accessor: PERMISSION_KEYS.FIND_PRODUCTS,
    disabled: false,
    name: 'Product Research'
  },
  {
    accessor: PERMISSION_KEYS.SUPPLIERS,
    disabled: false,
    name: 'Suppliers'
  },
  {
    accessor: PERMISSION_KEYS.KEYWORDS,
    disabled: false,
    name: 'Keywords'
  },
  {
    accessor: PERMISSION_KEYS.LAUNCH,
    disabled: false,
    name: 'Marketing'
  },
  {
    accessor: PERMISSION_KEYS.SALES_ANALYTICS,
    disabled: false,
    name: 'Sales Analytics'
  },
  {
    accessor: PERMISSION_KEYS.ADVERTISING,
    disabled: false,
    name: 'Advertising'
  },
  {
    accessor: PERMISSION_KEYS.MY_PRODUCTS,
    disabled: false,
    name: 'Manage Products'
  },
  {
    accessor: PERMISSION_KEYS.ACADEMY,
    disabled: false,
    name: 'Academy'
  },
  // Still missing feature gating
  {
    accessor: PERMISSION_KEYS.ALERTS,
    disabled: false,
    name: 'Alerts'
  },
  {
    accessor: PERMISSION_KEYS.TOOLBOX,
    disabled: false,
    name: 'Toolbox'
  },
  { name: 'Niche Hunter', accessor: 'niche_hunter', disabled: false },
  { name: 'Public API', accessor: 'public_api', disabled: false },
  {
    name: 'All Seller Tools',
    accessor: 'seller_kit',
    disabled: false,
    hidden: true
  }
]

// These are the options for the API feature dropdown
export const ALL_API_FEATURE_LIMIT_OPTIONS = [
  'public_api',
  'api_sales_estimator',
  'api_product_database',
  'api_segment',
  'api_share_and_trend',
  'api_keyword',
  'api_track_keyword',
  'api_share_of_voice',
  'api_keywords_by_asin',
  'api_keywords_by_keyword',
  'api_historical_search_volume',
  'api_asins_by_keywords_list'
]

export const COBALT_API_FEATURE_DROPDOWN_OPTIONS = [
  { name: 'sales_estimates_query', accessor: 'api_sales_estimator' },
  {
    name: 'product_database',
    accessor: 'api_product_database'
  },
  { name: 'segments', accessor: 'api_segment' },
  { name: 'keywords', accessor: 'api_keyword' },
  { name: 'track_keywords', accessor: 'api_track_keyword' },
  { name: 'share_of_voice', accessor: 'api_share_of_voice' }
]

export const ORANGE_API_FEATURE_DROPDOWN_OPTIONS = [
  { name: 'sales_estimates_query', accessor: 'api_sales_estimator' },
  {
    name: 'product_database_query',
    accessor: 'api_product_database'
  },
  { name: 'keywords_by_asin_query', accessor: 'api_keywords_by_asin' },
  { name: 'keywords_by_keyword_query', accessor: 'api_keywords_by_keyword' },
  {
    name: 'historical_search_volume',
    accessor: 'api_historical_search_volume'
  },
  { name: 'share_of_voice', accessor: 'api_share_of_voice' }
]

export const ACCESS_AND_LIMIT_DEFAULT = {
  access: true,
  limit: UNLIMITED_ACCESS_LIMIT,
  interval_timeframe: INDEFINITE_TIMEFRAME
}

export const FULL_DEFAULT_PERMISSION = {
  ...ACCESS_AND_LIMIT_DEFAULT,
  graph_days_limit: UNLIMITED_ACCESS_LIMIT,
  monthly_search_volume_graph_days_limit: UNLIMITED_ACCESS_LIMIT
}

export const EMPTY_ACCESS_RIGHT = {
  permissions: {
    ai_features: {
      access: false,
      limit: NO_ACCESS_LIMIT,
      interval_timeframe: INDEFINITE_TIMEFRAME,
      chat_limit: NO_ACCESS_LIMIT,
      chat_interval_timeframe: INDEFINITE_TIMEFRAME,
      chat_requests: NO_ACCESS_LIMIT
    },
    competitive_intelligence: {
      access: false,
      markets_limit: NO_ACCESS_LIMIT,
      market_items_limit: NO_ACCESS_LIMIT,
      market_keywords_limit: NO_ACCESS_LIMIT
    },
    fba_reimbursement: {
      access: false,
      limit: NO_ACCESS_LIMIT
    },
    membership: { user_limit: NO_ACCESS_LIMIT },
    extension: {
      access: true,
      csv_export_limit: UNLIMITED_ACCESS_LIMIT,
      details_page_interval_timeframe: INDEFINITE_TIMEFRAME,
      details_page_limit: UNLIMITED_ACCESS_LIMIT,
      keyword_cloud_csv_export_limit: UNLIMITED_ACCESS_LIMIT,
      keyword_cloud_interval_timeframe: INDEFINITE_TIMEFRAME,
      keyword_cloud_limit: UNLIMITED_ACCESS_LIMIT,
      program_access_interval_timeframe: INDEFINITE_TIMEFRAME,
      program_access_limit: UNLIMITED_ACCESS_LIMIT,
      review_csv_export_limit: UNLIMITED_ACCESS_LIMIT,
      search_page_interval_timeframe: INDEFINITE_TIMEFRAME,
      search_page_limit: UNLIMITED_ACCESS_LIMIT,
      product_graph_days_limit: UNLIMITED_ACCESS_LIMIT,
      seller_central_reviews_limit: UNLIMITED_ACCESS_LIMIT
    },
    keyword_scout: FULL_DEFAULT_PERMISSION,
    niche_hunter: FULL_DEFAULT_PERMISSION,
    listing_builder: {
      access: true,
      listings_limit: UNLIMITED_ACCESS_LIMIT
    },
    my_lists: { access: true, limit: UNLIMITED_ACCESS_LIMIT },
    product_database: ACCESS_AND_LIMIT_DEFAULT,
    product_tracker: {
      access: true,
      tracker_limit: 3,
      category_limit: 1,
      graph_days_limit: UNLIMITED_ACCESS_LIMIT
    },
    seller_kit: {
      access: true
    },
    rank_tracker: {
      tracked_keywords_limit: 5000
    },
    cobalt: {
      asin_limit: 10000,
      segment_limit: 500
    },
    find_products: { access: true },
    keywords: { ...ACCESS_AND_LIMIT_DEFAULT, tracker_limit: 3 },
    suppliers: { ...ACCESS_AND_LIMIT_DEFAULT, tracker_limit: 3 },
    academy: { access: true },
    alerts: { access: true, limit: UNLIMITED_ACCESS_LIMIT },
    advertising: { access: true, limit: UNLIMITED_ACCESS_LIMIT },
    sales_analytics: {
      access: true,
      limit: UNLIMITED_ACCESS_LIMIT
    },
    launch: { access: true, limit: UNLIMITED_ACCESS_LIMIT },
    inventory: { access: true, limit: UNLIMITED_ACCESS_LIMIT },
    my_products: { access: true },
    review_automation: {
      limit: UNLIMITED_ACCESS_LIMIT,
      order_skip: UNLIMITED_ACCESS_LIMIT,
      product_skip: UNLIMITED_ACCESS_LIMIT,
      order_delay: UNLIMITED_ACCESS_LIMIT,
      product_delay: UNLIMITED_ACCESS_LIMIT,
      marketplace_delay: UNLIMITED_ACCESS_LIMIT,
      order_bulk_update: UNLIMITED_ACCESS_LIMIT
    },
    toolbox: { access: true },
    sales_estimator: {
      access: true,
      limit: 500,
      interval_timeframe: 'month'
    },
    listing_grader: {
      access: true,
      limit: 500,
      interval_timeframe: 'month'
    },
    public_api: {
      access: true,
      limit: 100,
      interval_amount: 1,
      interval_timeframe: INDEFINITE_TIMEFRAME
    },
    api_product_database: { limit: UNLIMITED_ACCESS_LIMIT },
    api_sales_estimator: { limit: UNLIMITED_ACCESS_LIMIT },
    api_keywords_by_asin: { limit: UNLIMITED_ACCESS_LIMIT },
    api_keywords_by_keyword: { limit: UNLIMITED_ACCESS_LIMIT },
    api_historical_search_volume: { limit: UNLIMITED_ACCESS_LIMIT },
    api_share_of_voice: { limit: UNLIMITED_ACCESS_LIMIT },
    api_segment: { limit: NO_ACCESS_LIMIT },
    api_share_and_trend: { limit: NO_ACCESS_LIMIT },
    allowed_marketplaces: []
  }
}

export const CONSOLIDATED_PLAN_NAME = 'Consolidated'
export const BASIC_PLAN_NAME = 'Basic'

export const CORE_TIER_OPTIONS = [
  'Free',
  BASIC_PLAN_NAME,
  'Suite',
  'Pro',
  'FBB',
  'Starter',
  'GrowthAccelerator',
  'BrandOwner'
]

export const COBALT_TIER_OPTIONS = [BASIC_PLAN_NAME]

export const KIT_TIER_OPTIONS = [
  'Level 1',
  'Level 2',
  'Level 3',
  'Level 4',
  'Level 5'
]

export const EXTENSION_TIER_OPTIONS = ['JSL', 'JSP']

export const NEW_USER_NOTE_MODAL = 'NewUserNoteModal'
export const EDIT_USER_NOTE_MODAL = 'EditUserNoteModal'
export const VENDOR_CENTRAL_EMAIL_MODAL = 'VendorCentralEmailModal'

export const RESTRICTED_COLUMNS = [
  'exactSuggestedBidMedian',
  'broadSuggestedBidMedian',
  'monthlyTrend',
  'quarterlyTrend',
  'sponsoredProductCount',
  'sponsoredRank',
  'sponsoredRankingAsins',
  'avgCompetitorSponsoredRank'
]
